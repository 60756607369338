import { BarcodeData, HardwareService, PeripheralServiceConfig, PeripheralType } from '@dutchie/capacitor-hardware';
import { Peripheral, ScanBarcodePayload } from '../../index';
import { NativePeripheralService } from "../shared/NativePeripheralService";
import { NativeScanner } from "./NativeScanner";

type NativeScannerServiceFlags = {
  useServiceEventListeners: boolean
}
export class NativeScannerService extends NativePeripheralService<NativeScanner> {
  static flags: NativeScannerServiceFlags = {
    useServiceEventListeners: false
  }

  constructor(config?: Partial<PeripheralServiceConfig>) {
    super(PeripheralType.scanner, 'NativeScannerService', config)
  }

  async availableDevices(): Promise<NativeScanner[]> {
    const result = await Peripheral.searchScanners();
    return result.results.map((it) => new NativeScanner(it));
  }

  private handleScanData = (event: Event) => {
    const payload = (event as any) as ScanBarcodePayload;
    
    const barcode: BarcodeData = {
      bytes: new Uint8Array(payload.bytes),
      text: payload.string,
    };

    HardwareService.scanner
      .deviceById(payload.scanner.id)
      ?.emitBarcodeEvent(barcode);
  }

  attachEventListeners(): void {
    if (NativeScannerService.flags.useServiceEventListeners) {
      HardwareService.logger.d('Attach scanner service event listeners');
      window.addEventListener('onScannerData', this.handleScanData);
    }
  }

  detachEventListeners(): void {
    if (NativeScannerService.flags.useServiceEventListeners) {
      HardwareService.logger.d('Detach scanner service event listeners');
      window.removeEventListener('onScannerData', this.handleScanData);
    }
  }
}
