import { BarcodeData, ConnectionType, PeripheralMetadata, Scanner } from '@dutchie/capacitor-hardware';
import { NativeScannerService, Peripheral, Scanner as PerpiheralPackageScanner, ScanBarcodePayload } from '../../index';

export class NativeScanner extends Scanner {
  private device: PerpiheralPackageScanner;

  constructor(device: PerpiheralPackageScanner) {
    super(ConnectionType.usb);
    this.device = device;
  }

  get id(): string { return this.device.id; }
  get isConnected(): boolean { return this.device.connected; }
  get metadata(): PeripheralMetadata { 
    return {
      vendorId: this.device.vendor.id
    };
  }
  get name(): string { return this.device.name; }
  get peripheralData(): PerpiheralPackageScanner { return this.device as PerpiheralPackageScanner }
  get vendorId(): number { return this.device.vendor.id }

  private handleDataRef = this.handleData.bind(this);
  private handleData(event: Event) {
    const payload = (event as any) as ScanBarcodePayload;
    
    if (payload.scanner.id !== this.device.id) return;

    const barcode: BarcodeData = {
      bytes: new Uint8Array(payload.bytes),
      text: payload.string,
    };
    
    this.emitBarcodeEvent(barcode);
  }

  attachEventListeners(): void {
    if (NativeScannerService.flags.useServiceEventListeners) {
      return;
    }
    window.addEventListener('onScannerData', this.handleDataRef);
  }
  
  detachEventListeners(): void {
    if (NativeScannerService.flags.useServiceEventListeners) {
      return;
    }
    window.removeEventListener('onScannerData', this.handleDataRef);
  }

  async doConnect(): Promise<boolean> {
    const result = await Peripheral.connectScanner({ id: this.id });
    return result.success;
  }

  async doDisconnect(): Promise<boolean> {
    const result = await Peripheral.disconnectScanner({ id: this.id });
    return result.success;
  }
}
